import React from 'react';
import './LoadingScreen.css';
import Logo from '../../assets/WeddingLogo.png';
import BufferingVideo from '../../assets/Videos/Buffering.mp4';

function LoadingScreen({ fadeOut }) {
  return (
    <div className={`loading-screen ${fadeOut ? 'fade-out' : ''}`}>
      <img src={Logo} alt="Wedding Logo" className="loading-logo" />
      <video
        className="loading-video"
        autoPlay
        loop
        muted
        playsInline
        src={BufferingVideo}
      />
    </div>
  );
}

export default LoadingScreen;
