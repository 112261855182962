import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import LogoHeader from './components/LogoHeader/LogoHeader';

const Homepage = React.lazy(() => import('./pages/Homepage/Homepage'));
const RSVP = React.lazy(() => import('./pages/RSVP/RSVP'));
const FAQ = React.lazy(() => import('./pages/FAQ/FAQ'));
const Timeline = React.lazy(() => import('./pages/Timeline/Timeline'));
const Venue = React.lazy(() => import('./pages/Venue/Venue'));

function App({ className }) {
  return (
    <div className={`app-wrapper ${className}`}>
      <Router>
        <LogoHeader />
        <Navbar />
        <Suspense fallback={null}>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/rsvp" element={<RSVP />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/timeline" element={<Timeline />} />
            <Route path="/venue" element={<Venue />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
