import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import './index.css';

function Root() {
  const [isAppReady, setIsAppReady] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const loadAssets = async () => {
      // Simulate asset loading
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulating the asset loading time
      setFadeOut(true);
      setTimeout(() => {
        setIsAppReady(true);
      }, 1000); // Match this duration with CSS transition
    };

    loadAssets();
  }, []);

  return (
    <div className="root-container">
      <App className={isAppReady ? '' : 'hidden'} />
      {!isAppReady && <LoadingScreen fadeOut={fadeOut} />}
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);
