import "./LogoHeader.css";
import { Link } from "react-router-dom";

import Logo from "../../assets/Logo.png";

function LogoHeader() {
  return (
    <div className="logo-header_container">
      <Link to="/">
        <img className="logo-header_logo-image" src={Logo} alt="Logo" />
      </Link>
    </div>
  );
}

export default LogoHeader;
