import React, { useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    function toggleMobileMenu() {
        setMobileMenuOpen(!isMobileMenuOpen);
    }

    function handleMobileLinkClick() {
        setMobileMenuOpen(false); // Close the mobile menu when a link is clicked
    }

    return (
        <>
            <BrowserView>
                <div className="navbar_desktop-container">
                    <Link to="/" className="navbar_desktop-button">Home</Link>
                    <Link to="/rsvp" className="navbar_desktop-button">RSVP</Link>
                    <Link to="/faq" className="navbar_desktop-button">FAQs</Link>
                    <Link to="/timeline" className="navbar_desktop-button">Timeline</Link>
                    <Link to="/venue" className="navbar_desktop-button">Venue</Link>
                </div>
            </BrowserView>
            <MobileView>
                <div className={`navbar_mobile-container ${isMobileMenuOpen ? 'open' : 'closed'}`}>
                    <button className="navbar_mobile-menu-toggle" onClick={toggleMobileMenu}>
                        {isMobileMenuOpen ? '✕' : '☰'}
                    </button>
                    {isMobileMenuOpen && (
                        <div className="navbar_mobile-menu">
                            <Link to="/" className="navbar_mobile-button" onClick={handleMobileLinkClick}>Home</Link>
                            <Link to="/rsvp" className="navbar_mobile-button" onClick={handleMobileLinkClick}>RSVP</Link>
                            <Link to="/faq" className="navbar_mobile-button" onClick={handleMobileLinkClick}>FAQs</Link>
                            <Link to="/timeline" className="navbar_mobile-button" onClick={handleMobileLinkClick}>Timeline</Link>
                            <Link to="/venue" className="navbar_mobile-button" onClick={handleMobileLinkClick}>Venue</Link>
                        </div>
                    )}
                </div>
            </MobileView>
        </>
    );
}

export default Navbar;
